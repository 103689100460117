interface IConfig {
  baseURL: string;
}

let Config: IConfig;

switch (process.env.REACT_APP_ENV) {
  case 'development':
    Config = {
      baseURL: 'https://staging.mediquestevents.in'
    };
    break;
  case 'test':
    Config = {
      baseURL: 'https://staging.mediquestevents.in'
    };
    break;
  case 'production':
    Config = {
      baseURL: 'https://staging.mediquestevents.in'
    };
    break;
  default:
    Config = {
      baseURL: 'https://staging.mediquestevents.in',
    };
    break;
}

export default Config;
