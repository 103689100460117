import { Box } from "@mui/material";
import ButtonCustom from "../../components/ButtonCustom";
import { InputWithLabel } from "../../components/InputWithLabel";
import AuthLayout from "../../layouts/AuthLayout";
import { Form, Formik } from "formik";
import { loginInitialValues, otpInitialValues } from "./validations/initialValues";
import { loginValidationSchema, otpValidationSchema } from "./validations";
import { useEffect, useState } from "react";
import { convertSecondsToMinutes } from "../../util/convertSecondsToMinutes";
import { useNavigate } from "react-router-dom";
import { getUserProfile, postLogin } from "../../api/api";

const otpTimer = 300;

const LoginPage = () => {
    const [loading, setLoading] = useState(false);
    const [showOtp, setShowOtp] = useState(false);
    const [counter, setCounter] = useState(0);
    const [isResendDisabled, setIsResendDisabled] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (showOtp) {
            const timer: any = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
            // Disable the resend button when the counter is active
            setIsResendDisabled(counter > 0);

            // Reset the counter and enable the resend button when it reaches 0
            if (counter === 0) {
                clearInterval(timer);
                setIsResendDisabled(false);
            }

            return () => clearInterval(timer);
        }
    }, [counter, showOtp]);

    const sendOtpHandler = async (values: any) => {
        setShowOtp(false);
        setCounter(otpTimer);

        // setLoading(true);
        if (values.email && values.phone) {
            const formData = new FormData();
            formData.append('mobile', values.phone);
            formData.append('email', values.email);

            const response = await getUserProfile();

            console.log(response);
            // const response = true;

            // if (response) {
            //     setShowOtp(true);
            //     setLoading(false);
            // }

        }
    }

    const submitOtpFormHandler = async (values: any) => {
        setLoading(true);
        await new Promise(r => setTimeout(r, 1000))
        const response = values;

        if (response.otp) {
            setLoading(false);
            navigate('/events-v2');
        }
    }

    return (
        <AuthLayout>
            <div className="auth-card-custom">
                <div>
                    <h2 className="auth-heading40">Login</h2>
                    <p className="auth-subtitle">
                        Unlock access to events and content
                        with your login credentials.
                    </p>

                    <Formik initialValues={loginInitialValues} validationSchema={loginValidationSchema} onSubmit={sendOtpHandler}>
                        {
                            ({ values, handleChange, handleBlur }) => (
                                <Form>
                                    <div className="form-container">
                                        <InputWithLabel
                                            label="Registered Email"
                                            required
                                            placeholder="Enter Email Address"
                                            name="email"
                                            onChange={handleChange}
                                            value={values.email}
                                            onBlur={handleBlur}
                                        />
                                        <InputWithLabel
                                            label="Registered mobile No."
                                            required
                                            placeholder="Enter Mobile Number"
                                            name="phone"
                                            onChange={handleChange}
                                            value={values.phone}
                                            onBlur={handleBlur}
                                        />
                                        <ButtonCustom variant="secondary" type='submit' disabled={loading || isResendDisabled}> Generate OTP </ButtonCustom>
                                    </div>
                                </Form>
                            )
                        }
                    </Formik>

                    {showOtp &&
                        <>
                            <p className="error">OTP sent to your registered credentials</p>
                            <p className="error text-right">Time left: {convertSecondsToMinutes(counter)}</p>

                            <Formik initialValues={otpInitialValues} validationSchema={otpValidationSchema} onSubmit={submitOtpFormHandler}>
                                {
                                    ({ values, handleChange, handleBlur }) => (
                                        <Form>
                                            <div className="form-container mt-12">
                                                <InputWithLabel
                                                    label=""
                                                    placeholder="Enter OTP"
                                                    name="otp"
                                                    onChange={handleChange}
                                                    value={values.otp}
                                                    onBlur={handleBlur}
                                                />
                                                <Box sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "16px"
                                                }}>
                                                    <ButtonCustom type='submit' disabled={loading}> Submit </ButtonCustom>
                                                </Box>
                                            </div>
                                        </Form>
                                    )
                                }
                            </Formik>
                        </>
                    }

                </div>
            </div>
        </AuthLayout>
    );
}

export default LoginPage;