import { Box } from "@mui/material";
import EventSliderSectionV2 from "../sections/EventSliderSectionV2";
import dummyEvents from '../data/events.json';

interface UpcomingEventsSectionProps {
    title: string;
}

const UpcomingEventsSection: React.FC<UpcomingEventsSectionProps> = ({
    title
}) => {
    return (
        <Box
            className='custom-container'
            sx={{
                paddingBottom: "20px"
            }}
        >
            <EventSliderSectionV2
                title={title}
                themeColor={'#E14E61'}
                data={dummyEvents}
            />
        </Box>
    );
}

export default UpcomingEventsSection;