import React, { useMemo, useRef, useState } from 'react';
import Slider from 'react-slick';
import { Box, Pagination, PaginationItem } from '@mui/material';

// styles for 'Slider'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { stripHtml } from '../util/html';
import EventCardV2 from '../components/EventCardV2';
import TextButton from '../components/TextButton';
import { slideToShow } from '../constants/slider';

interface EventSliderSectionV2Props {
  title: string;
  themeColor: string;
  data: any[];
}

const EventSliderSectionV2: React.FC<EventSliderSectionV2Props> = ({
  title,
  themeColor,
  data
}) => {
  const { size } = useSelector((state: RootState) => state.mquery);
  const [activeSlide, setActiveSlide] = useState(1);
  const sliderRef = useRef<Slider | null>(null);
  const totalSlide = useMemo(() => data?.length, [data])

  const ArrowBackIcon = (props: any) => {
    const { className, onClick } = props;
    return (
      <TextButton className={className} content='Previous' arrowLeft onClick={onClick} disabled={!onClick} />
    )
  }
  const ArrowForwardIcon = (props: any) => {
    const { className, onClick } = props;
    return (
      <TextButton className={className} content='Next' arrow onClick={onClick} disabled={!onClick} />
    )
  }

  const handlePaginationClick = (event: React.ChangeEvent<unknown>, value: number) => {
    // setActiveSlide(value);
    sliderRef?.current?.slickGoTo(value - 1);
  };

  const settings = {
    dots: false,
    // dotsClass: 'slick-dots-custom',
    infinite: true,
    speed: 500,
    // autoplay: true,
    // autoplaySpeed: 5000,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: slideToShow.md,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: slideToShow.sm,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: slideToShow.xs,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      }
    ],
    customPaging: (i: number) => (
      <span>{i + 1}</span>
    ),
    nextArrow: <ArrowForwardIcon />,
    prevArrow: <ArrowBackIcon />,
    beforeChange: (current: number, next: number) => {
      setActiveSlide(next + 1);
    },
  };

  return (
    <Box>
      {data?.length ?
        <Box
          style={{
            overflowX: 'clip',
            overflowY: 'initial',
            paddingTop: "40px"
          }}
        >
          <Box mx={0} mb='25px' className="custom-slider-container">
            <Box className="mb-32">
              <h3 className="main-heading32">
                {title}
              </h3>
            </Box>

            <Slider
              ref={sliderRef}
              {...settings}
            >
              {data?.map(
                (item: any, index: number) => (
                  <Box key={index}>
                    <EventCardV2
                      id={item.id}
                      imageSrc={item.img}
                      date={new Date(item.date)}
                      title={item.title}
                      description={item.description}
                    />
                  </Box>
                )
              )}
            </Slider>

            <Pagination
              count={totalSlide}
              page={activeSlide}
              onChange={handlePaginationClick}
            />
          </Box>
        </Box>
        : null
      }

    </Box>

  );
};

export default EventSliderSectionV2;
