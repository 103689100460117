import React, { useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import {
  AppBar,
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  SwipeableDrawer,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import LogoText from '../components/LogoText';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import LogoImage from '../components/LogoImage';
import LogoutModal from '../components/modals/LogoutModal';
import ButtonCustom from '../components/ButtonCustom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Avater from '../components/Avater';
import { FaRegBookmark } from 'react-icons/fa';
import { IoPowerSharp } from 'react-icons/io5';

const drawerWidth = '80vw';

const NavList: Array<NavListProps> = [
  { id: 1, to: '/', label: 'Home' },
  { id: 2, to: '/events', label: 'Events' },
  { id: 3, to: '/gallery', label: 'Gallery' },
  { id: 4, to: '/about-us', label: 'About Us' },
  { id: 5, to: '/contact-us', label: 'Contact Us' },
];

interface NavLinkProps {
  to: string;
  children: React.ReactNode;
}
interface NavListProps {
  id: number;
  to: string;
  label: string;
}
interface CTAButtonsProps {
  handleLogout: () => void;
}

const NavLink: React.FC<NavLinkProps> = ({
  to,
  children,
  ...props
}: NavLinkProps) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <Link to={to} style={{ textDecoration: 'none' }} {...props}>
      <Typography
        textTransform="none"
        variant="button"
        color={isActive ? 'primary' : 'black'}
        sx={{ fontSize: '1rem', fontWeight: 'bold' }}
      >
        {children}
      </Typography>
    </Link>
  );
};

const CTAButtons = ({ handleLogout }: CTAButtonsProps) => {
  return (
    <Box className="cta-menu">
      <Link to={"/contact-us"} className='get-in-touch'>
        <ButtonCustom
          endIcon={<ArrowForwardIcon
            sx={{ rotate: "-45deg" }} />}
          className='fz-16 px-16 py-10'
        >
          Get In Touch
        </ButtonCustom>
      </Link>

      <div className='menu-hover-container'>
        <ButtonCustom
          variant="secondary"
          startIcon={<Avater name='Ron' />}
          className='px-10 py-6 user-menu-icon'
        >
          <span className='user-phone'>
            01382326656
          </span>
        </ButtonCustom>
        <div className='menu-contents'>
          <ul>
            <li>
              <Link to={'#'} className='item'>
                <span className='icon'>
                  <FaRegBookmark />
                </span>
                <span>Bookmarks</span>
              </Link>
            </li>
            <li>
              <button onClick={handleLogout} type='button' className='item'>
                <span className='icon'>
                  <IoPowerSharp />
                </span>
                <span>Logout</span>
              </button>
            </li>
          </ul>
        </div>

      </div>
    </Box>
  )
}

const NavBar: React.FC = () => {
  const theme = useTheme();
  const [navbaVisible, setNavbarVisible] = useState<boolean | null>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navRef = useRef<HTMLDivElement>(null);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);

  const { siteBasicInfoData, siteBasicInfoLoading } = useSelector(
    (state: RootState) => state.api
  );

  const logoImage = siteBasicInfoData?.data?.logo;
  const logoUrl = siteBasicInfoData?.data?.url;

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setNavbarVisible(true);
        } else {
          setNavbarVisible(false);
        }
      });
    });

    if (navRef.current) {
      observer.observe(navRef.current);
    }

    return () => {
      if (navRef.current) {
        observer.unobserve(navRef.current);
      }
    };
  }, []);

  const handleOpenLogoutModal = () => setLogoutModalOpen(true);
  const handleCloseLogoutModal = () => setLogoutModalOpen(false);

  const handleLogout = () => {
    handleCloseLogoutModal();
  }

  return (
    <div ref={navRef}>
      <div className='header-box'
        style={{
          position: navbaVisible ? 'static' : 'fixed',
          top: 0,
          width: '100%',
          zIndex: 5,
          backgroundColor: 'white'
        }}
      >
        <Box className='custom-container'>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className="header-inner"
          >
            <Grid className='logo-img' item>
              <LogoImage
                imgSrc={logoImage}
                url={logoUrl}
                height={isMobile || !navbaVisible ? 22.4 : 32}
                width={isMobile || !navbaVisible ? 111.167 : 158.8}
              />
            </Grid>

            {!isMobile && (
              <Grid item flexGrow={1}>
                <Grid container className='nav-links'>
                  <Toolbar
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                      height: navbaVisible ? '5rem' : '3rem',
                      transition: 'all 0.3s',
                      // flexBasis: '870px',
                      // maxWidth: '870px',
                      textAlign: 'right'
                    }}
                    className='nav-link-gap'
                  >
                    {NavList.map((item) => (
                      <NavLink key={item.id} to={item.to}>
                        {item.label}
                      </NavLink>
                    ))}
                  </Toolbar>
                </Grid>
              </Grid>
            )}

            {
              !isMobile && (
                <Grid item>
                  <CTAButtons handleLogout={handleOpenLogoutModal} />
                </Grid>
              )
            }

            {isMobile && (
              <Grid item>
                <IconButton onClick={toggleDrawer}>
                  <MenuRoundedIcon />
                </IconButton>
                <SwipeableDrawer
                  anchor="left"
                  open={isDrawerOpen}
                  onClose={toggleDrawer}
                  onOpen={toggleDrawer}
                  sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': { width: drawerWidth },
                  }}
                >
                  <Box sx={{ position: 'absolute' }}>
                    <Grid
                      container
                      justifyContent="center"
                      marginTop={2}
                      ml={1}
                    >
                      <Grid item>
                        <LogoText label="medi,quest" size={1.4} />
                      </Grid>
                    </Grid>
                    <List sx={{ width: '150%' }}>
                      {NavList.map((item) => (
                        <ListItemButton
                          key={item.id}
                          component={Link}
                          to={item.to}
                          onClick={toggleDrawer}
                        >
                          <ListItemText primary={item.label} />
                        </ListItemButton>
                      ))}

                      <ListItemButton
                        component={Link}
                        to={'/bookmarks'}
                      >
                        <ListItemText primary={"Bookmarks"} />
                      </ListItemButton>
                      <ListItemButton onClick={handleOpenLogoutModal} className='btn' href='#'>
                        <ListItemText primary={"Logout"} />
                      </ListItemButton>
                    </List>
                  </Box>
                </SwipeableDrawer>
              </Grid>
            )}
          </Grid>
        </Box>
      </div>

      <LogoutModal open={logoutModalOpen} handleClose={handleCloseLogoutModal} handleLogout={handleLogout} />
    </div>
  );
};

export default NavBar;
