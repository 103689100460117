import React from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import {
  fetchAllEventsData,
  fetchCategoriesData,
  fetchPastEventsData,
  fetchUpcomingEventsData,
} from '../redux/apiSlice';
import theme from '../theme/basic';
import RegisteredEventsSection from './RegisteredEventsSection';
import UpcomingEventsSection from './UpcomingEventsSection';
import WelcomeSection from '../sections/WelcomeSection';
import LinearIndeterminate from '../components/LinearIndeterminate';

const WelcomePage: React.FC = () => {
  const dispatch = useDispatch();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    siteBasicInfoData,
    heroImageLoaded,
    pastEventsData,
    pastEventsLoading,
  } = useSelector((state: RootState) => state.api);

  const aboutMediquestEvents = siteBasicInfoData?.data?.aboutMediquestEvents;
  const aboutMediquest = siteBasicInfoData?.data?.aboutMediquest;

  React.useEffect(() => {
    dispatch(fetchUpcomingEventsData());
    dispatch(fetchPastEventsData());
    dispatch(fetchAllEventsData()); // add
    dispatch(fetchCategoriesData());
  }, []);

  if (pastEventsLoading) {
    return <LinearIndeterminate />;
  }

  return (
    <>

      <WelcomeSection description />

      <RegisteredEventsSection
        title={'Registered Events'}
      />

      <UpcomingEventsSection
        title={'Upcoming Events'}
      />


    </>
  );
};

export default WelcomePage;
