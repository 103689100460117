import axios, { AxiosInstance } from "axios";
import { IUpcomingEvent } from "./interfaces/UpcomingEventInterface";
import { IPastEvent } from "./interfaces/PastEventInterface";
import { IEventFullDetailsResponse } from "./interfaces/EventFullDetailsInterface";
import { IEvent } from "./interfaces/AllEventInterface";
import { ICategory } from "./interfaces/CategoryInterface";
import { ISiteBasicInfoResponse } from "./interfaces/SiteBasicInfoInterface";
import Config from "../config";
import { IPrimaryMenuResponse } from "./interfaces/PrimaryMenuInterface";
import { IFooterContentResponse } from "./interfaces/FooterContentInterface";
import { IContact } from "./interfaces/ContactInterface";
import { IGalleryResponse } from "./interfaces/galleryInterface";
import { APP_CONSTANTS } from "../constants/app.constants";

const baseURL = Config.baseURL;
const token = `YXBpdXNlcjphcGlAMTIz`;
const authToken = localStorage.getItem(APP_CONSTANTS.LOCAL_STORAGE_TOKEN_KEY);

// Create a new Axios instance
const api: AxiosInstance = axios.create({
  baseURL: baseURL,
});

console.log(baseURL);


// Add the interceptor
/**
 * @TODO add type to 'config'
 *
 */
api.interceptors.request.use(
  (config: any) => {
    config.headers = {
      ...config.headers,
      Authorization: `Basic ${token}`,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const upcomingEventsData = async (): Promise<IUpcomingEvent[]> => {
  const response = await api.get<IUpcomingEvent[]>(
    `${baseURL}/wp-json/wp/v2/posts?_fields=id,title,content,status,featured_image,featured_media,date_gmt,excerpt,event_date&per_page=10&categories=9`
  );
  return response.data;
};

export const pastEventsData = async (): Promise<IPastEvent[]> => {
  const response = await api.get<IPastEvent[]>(
    `${baseURL}/wp-json/wp/v2/posts?_fields=id,title,content,featured_image,featured_media,date_gmt,date,format,excerpt,event_date,template&per_page=10&categories=3`
  );
  return response.data;
};

export const eventFullDetailsData = async (
  id: string | undefined
): Promise<IEventFullDetailsResponse> => {
  const response = await api.get<IEventFullDetailsResponse>(
    `${baseURL}/wp-json/event/details?event_id=${id}`
    // {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    // }
  );
  return response.data;
};

export const allEventsData = async (): Promise<IEvent[]> => {
  const response = await api.get<IEvent[]>(
    `${baseURL}/wp-json/wp/v2/posts?_fields=id,title,content,featured_image,featured_media,date_gmt,date,format,template,excerpt,event_date,categories,&per_page=10`
  );
  return response.data;
};

export const categoriesData = async (): Promise<ICategory[]> => {
  const response = await api.get<ICategory[]>(
    `${baseURL}/wp-json/wp/v2/categories?hide_empty=1&_fields=id,count,name,parent`
  );
  return response.data;
};

export const siteBasicInfoData = async (): Promise<ISiteBasicInfoResponse> => {
  const response = await api.get<ISiteBasicInfoResponse>(
    `${baseURL}/wp-json/site/basic_info`
  );
  return response.data;
};

export const primaryMenuData = async (): Promise<IPrimaryMenuResponse> => {
  const response = await api.get<IPrimaryMenuResponse>(
    `${baseURL}/wp-json/menu/primary`
  );
  return response.data;
};

export const footerContentData = async (): Promise<IFooterContentResponse> => {
  const response = await api.get<IFooterContentResponse>(
    `${baseURL}/wp-json/menu/footer`
  );
  return response.data;
};

export const contactData = async (): Promise<IContact[]> => {
  const response = await api.get<IContact[]>(
    `${baseURL}/wp-json/wp/v2/pages?slug=contact-us`
  );
  return response.data;
};

export const galleryData = async (): Promise<IGalleryResponse> => {
  const response = await api.get<IGalleryResponse>(
    `${baseURL}/wp-json/site/galaries`
  );
  return response.data;
};

export const postLogin = async (data: any): Promise<any> => {
  const response = await api.post(`${baseURL}/wp-json/login/request-otp`, data);
  return response.data;
};

export const postOtp = async (data: any): Promise<any> => {
  const response = await api.post(`${baseURL}/wp-json/login/verify-otp`, data);
  return response.data;
};

export const getUserProfile = async (id: string = "772"): Promise<any> => {
  const response = await api.get<any>(
    `${baseURL}/wp-json/user/profile?user_id=${id}`
  );
  return response.data;
};

export const getRegisteredEvents = async (id: string = "772"): Promise<any> => {
  const response = await api.get(
    `${baseURL}/wp-json/user/registered-events?user_id=${id}`
  );
  return response.data;
};
