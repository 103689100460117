import { Box } from "@mui/material";

type Props = {
    lastLogin?: string;
    description?: boolean;
}

const WelcomeSection = ({ lastLogin, description = false }: Props) => {
    return (
        <Box className='custom-container welcome-container' pt='50px'>
            <h2 className='heading40 text-left'>
                <span>Welcome,</span> Dr. Ranjan Pal
            </h2>

            <div className='location-container text-gray'>
                <span>
                    {!!lastLogin ? `Last Logged In :${lastLogin}` : "MDS, FACC"}
                </span>
                <span>
                    Mumbai, India
                </span>
            </div>

            {description &&
                <p className='text-gray font-manrope'>
                    Access our comprehensive collection of medical event resources. Stay informed and ahead of the curve with our downloadable materials.
                </p>
            }
        </Box>
    );
}

export default WelcomeSection;