import { Box } from "@mui/material";
import CardMultiBordered from "../components/CardMultiBordered";

const CoursePresentationSection = () => {
    return (
        <Box className="custom-container">
            <h3 className="main-heading32 mb-28">Course Presentations</h3>
            <CardMultiBordered title="Meet the Professor Session 1" />
            <CardMultiBordered title="Meet the Professor Session 2" />
            <CardMultiBordered title="Meet the Professor Session 3" />
        </Box>
    );
}

export default CoursePresentationSection;