import { Outlet } from 'react-router';
import NavBar from '../sections/HeaderSection';
import FooterSection from '../sections/FooterSection';

const WithNavFooter = () => {
    return (
        <>
            <NavBar />
            <div className="pt-68">
                <Outlet />
            </div>
            <FooterSection />
        </>
    );
};

export default WithNavFooter;