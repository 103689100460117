import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import LogoText from '../components/LogoText';
import { getCurrentYear } from '../util/date';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import RenderedContent from '../components/RenderedContent';
import { markAsUntransferable } from 'worker_threads';
// import styles from "../assets/scss/abc.scss";
import { footerRoutes } from '../constants/footer';
import { red } from '@mui/material/colors';
import { RiFacebookFill } from 'react-icons/ri';
import { IoLogoInstagram } from 'react-icons/io5';
import { FaGithub, FaLinkedin } from 'react-icons/fa';


interface NavLinkProps {
  to: string;
  children: React.ReactNode;
}

const socials = [
  {
    title: "facebook",
    url: "#",
    icon: <RiFacebookFill />
  },
  {
    title: "instagram",
    url: "#",
    icon: <IoLogoInstagram />
  },
  {
    title: "linkedIn",
    url: "#",
    icon: <FaLinkedin />
  },
  {
    title: "github",
    url: "#",
    icon: <FaGithub />
  },
]

const NavLink: React.FC<NavLinkProps> = ({
  to,
  children,
  ...props
}: NavLinkProps) => {
  return (
    <Link to={to} style={{ textDecoration: 'none' }} {...props}>
      <Typography
        textTransform="none"
        variant="button"
        color={'white'}
        sx={{ fontSize: '1rem' }}
      >
        {children}
      </Typography>
    </Link>
  );
};

const FooterSection: React.FC<any> = () => {
  const { siteBasicInfoData, footerContentData } = useSelector(
    (state: RootState) => state.api
  );

  const logUrl: string = siteBasicInfoData?.data?.url as string;
  const name: string = siteBasicInfoData?.data?.name as string;
  // const footerData = footerContentData?.data;
  // const links = Object.values(footerData ? footerData : {});
  const links = footerRoutes;

  return (
    <Box className="footer-box" bgcolor={'primary.main'}>
      <Box className="footer-inner">
        {/* disableGutters fixed */}
        <Box className="custom-container">
          {/* <Link to={logUrl}>
            <LogoText
              label="medi,quest"
              size={1.625}
              weight="bold"
              colors={['white', 'secondary']}
            />
          </Link> */}
          <Box display={'flex'} justifyContent={'center'} >
            <RenderedContent>{name}</RenderedContent>
          </Box>

          <Grid className='footer-links'
            container
            color="white"
          // spacing={2}
          >
            {links.map((link, index) => (
              <React.Fragment key={index}>
                <Grid item>
                  <NavLink to={link.route}>{link.label}</NavLink>
                  {/* <RenderedContent>{link}</RenderedContent> */}
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Box>
      </Box>

      <Box className='copyright-box'>
        <Box className='custom-container'>
            <Typography>             
             Copyright &copy;{getCurrentYear()} MediQuest | All Rights Reserved
            </Typography>
        </Box>
      </Box>  

    </Box>
  );
};

export default FooterSection;
