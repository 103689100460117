import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from "react-router-dom";
import EventCardV2 from "../components/EventCardV2";
import EventSliderSectionV2 from "../sections/EventSliderSectionV2";
import dummyEvents from '../data/events.json';

interface RegisteredEventsSectionProps {
    title: string;
}

const RegisteredEventsSection: React.FC<RegisteredEventsSectionProps> = ({
    title
}) => {
    return (
        <Box className='custom-container'>
            <EventSliderSectionV2
                title={title}
                themeColor={'#E14E61'}
                data={dummyEvents}
            />
        </Box>
    );
}

export default RegisteredEventsSection;