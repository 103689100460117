import { Box, Button, Grid } from "@mui/material";
import CustomCardSm from "./CustomCardSm";
import { GrDownload } from "react-icons/gr";
import { MdOutlineBookmarkBorder } from "react-icons/md";

const CardMultiBordered = ({ title = '', professor = 'Dr. Joy Y. Wu' }) => {
    return (
        <Box className="custom-card-multi-border">
            <Box className="top">
                <Box sx={{
                    display: "flex",
                    gap: "20px"
                }}>
                    <Box>
                        <h5 className="title">
                            {title}
                        </h5>
                        <Box className="subtitle">
                            Management of Thyroid Hormone Replacement in vulnerable populations -
                            Elderly, Pregnancy
                        </Box>

                        <Box className="prof">
                            {professor}
                        </Box>
                    </Box>
                    <Button className="rounded-btn" sx={{ mr: "34px", marginTop: "0 !important" }}>
                        <MdOutlineBookmarkBorder size={24} />
                    </Button>
                </Box>

            </Box>

            <Box className="bottom">

                <Grid container>
                    {
                        dummyData?.map(item => (
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                key={item.title}
                            >
                                <Box m={"10px"} pb="12px" height="100%">
                                    <CustomCardSm
                                        thumbnail='video'
                                        downloadIcon={<Button className="rounded-btn download">
                                            <GrDownload />
                                        </Button>}
                                    >
                                        <Box>
                                            <Box className="fz-17 fw-700">
                                                {item.title}
                                            </Box>
                                        </Box>
                                    </CustomCardSm>
                                </Box>
                            </Grid>
                        ))
                    }
                </Grid>
            </Box>
        </Box>
    );
}

export default CardMultiBordered;

const dummyData = [
    {
        title: "Trailer.mp4"
    },
    {
        title: "Full video presentation.mp4"
    },
    {
        title: "Presentation slides.pdf"
    }
]